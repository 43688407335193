import { Title } from '@solidjs/meta';
import { mergeProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Link, useFocusable } from '@troon/ui';
import type { ParentProps, ComponentProps } from 'solid-js';

export default function Errors() {
	return (
		<>
			<Title>Errors / Styleguide</Title>

			<h1 class="mb-8 text-4xl font-semibold">Errors</h1>

			<ul class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard
					href="/_/styleguide/errors"
					onClick={(e) => {
						e.preventDefault();
						throw new Error('This is a test');
					}}
				>
					Throw an error on click
				</StyleCard>
				<StyleCard href="/_/styleguide/errors/error-404-example">404 page</StyleCard>
				<StyleCard href="/_/styleguide/errors/error-boundary-example">Error boundary</StyleCard>
			</ul>
		</>
	);
}

function StyleCard(inputProps: ParentProps<{ class?: string } & ComponentProps<typeof Link>>) {
	const focusProps = useFocusable();
	const props = mergeProps(inputProps, focusProps);
	return (
		<li class="col-span-12 md:col-span-6 lg:col-span-4 2xl:col-span-3">
			<Link
				{...props}
				class={twMerge(
					'flex flex-col gap-2 overflow-hidden rounded-md border border-neutral-500 px-4 py-2 text-lg font-semibold outline-none transition-all duration-200 hover:border-brand-400 hover:bg-brand-100/10 focus-visible:ring-4 focus-visible:ring-brand-100',
					props.class ?? '',
				)}
			>
				{props.children}
			</Link>
		</li>
	);
}
